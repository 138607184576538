.floating-snap-btn-wrapper {
  position: fixed;
  width: 80px; /* Default for desktop */
  height: 80px;
  border-radius: 50%;
  z-index: 99999999;
  transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
}

.fab-btn {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #0048ff;
  color: white;
  box-shadow: 0px 2px 17px -1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

/* Adjust for mobile */
@media only screen and (max-width: 767px) {
  .floating-snap-btn-wrapper {
    width: 50px;
    height: 50px;
  }
}

body.no-scroll {
  overflow: hidden; 
  touch-action: none; 
}
