@import "variables";
@import "mixins";

::-webkit-scrollbar-thumb {
  width: 0 !important;
  height: 0 !important;
}

.sign-in {
  color: #fff !important;
}

.mobileLogo {
  @include mq($q14 - 1) {
    margin: 0 auto;
  }
}

.infinite-scroll-component {
  overflow: initial !important;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999999;

  @include mq($q12) {
    right: 20px;
    bottom: 170px;
  }

  @include mq($q10) {
    bottom: 95px;
  }

  // .rtl-class & {
  //   @include mq($q12) {
  //     bottom: 160px;
  //   }
  // }

  .arrowWrap {
    background-color: #fe5900;
    border-radius: 50%;
    position: relative;
    padding: 18px;
    cursor: pointer;
    transition-duration: 0.5s;
    opacity: 0.75;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 68px;
    height: 68px;

    @include mq($q10) {
      width: 48px;
      height: 48px;
    }
  }

  .arrowWrap:hover {
    transform: scale(1.1);
    transform: scale(1.1);
    transform: scale(1.1);
  }
}

.main-component {
  min-height: 88px;
  position: sticky;
  top: 0;
  z-index: 99999;

  @media (max-width: ($grid-breakpoint-lg)-1) {
    min-height: 80px;
  }

  .ulstyle {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.auth {
    @media (min-width: $grid-breakpoint-lg) {
      min-height: 128px;
    }
  }

  .header {
    a {
      color: white !important;
      display: flex;
    }

    @media (max-width: 500px) {
      position: fixed !important;
    }

    > div {
      justify-content: center;

      @media (max-width: ($grid-breakpoint-lg)-1) {
        justify-content: space-between;

        &::after {
          background-color: #01222e;
          content: "";
          position: absolute;
          width: 56px;
          height: 56px;
          top: 29px;
          z-index: -10;
          text-align: center;
          border-radius: 50%;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
        }
      }

      .icon_block {
        width: 24px;

        .alerts-parent {
          right: 0px;

          .rtl-class & {
            left: 0px;
            right: unset;
          }
        }
      }
    }

    @media (max-width: ($grid-breakpoint-lg)-1) {
      background-color: #01222e;
      width: 100%;
      z-index: 10000001;
    }

    @media (min-width: $grid-breakpoint-lg) {
      background-color: $primary;
      width: 100%;
      z-index: 10000;
      box-shadow: 0 4px 16px rgba(121, 42, 0, 0.08);
    }

    ul {
      @media (max-width: ($grid-breakpoint-lg)-1) {
        display: none !important;
      }
    }

    .ul-block ul a {
      padding: $spacer-5 $spacer-4;
      display: inline-block;

      @media (min-width: $grid-breakpoint-lg) {
        padding: $spacer-5 $spacer-2;
      }

      &:hover {
        background: #e24f00;
        text-decoration: none;
      }

      p {
        color: white !important;
        font-size: 14px;
      }
    }

    .ul-block .active {
      background: #e24f00 !important;
      text-decoration: none;
    }

    .auth_menu .active {
      background: $primary-25 !important;
      text-decoration: none;
    }

    .input-search {
      align-items: center;
      margin: $spacer-4;
      position: relative;
      border-radius: 30px;

      .arrow {
        left: 0;
        opacity: 1;
        left: 5px;
        top: 10px;
      }

      .zoom {
        content: "";
        background-image: url("./images/zoom.svg");
        position: absolute;
        width: 24px;
        height: 24px;
        right: 11px;
        top: 8px;
        opacity: 1;
      }

      .rtl-class & .zoom {
        left: 0;
        right: unset;
      }

      .cam {
        content: "";
        background-image: url("./images/camera.svg");
        position: absolute;
        width: 25px;
        height: 25px;
        right: 40px;
        top: 8px;
        opacity: 1;
        display: none;
      }

      .rtl-class & .cam {
        left: 0;
        right: unset;
      }

      input {
        border-radius: 30px;
        border: 0 white;
        height: 40px;
        font-size: 16px;
        padding: $spacer-2 $spacer-3;
        position: relative;
        background: white;
        color: $primary-75;
        width: 100%;

        // @include mq($q1800) {
        //   width: 500px;
        // }

        // @include mq($q17) {
        //   width: 500px;
        // }

        // @include mq($q16) {
        //   width: 500px;
        // }

        &::placeholder {
          color: $primary-75;
          font-weight: bold;
        }

        &:focus {
          outline: none;
        }
      }

      .rtl-class &::after {
      }

      @media (max-width: ($grid-breakpoint-lg)-1) {
        // display: none !important;
        .zoom,
        .arrow {
          opacity: 0;
          transition: opacity 0.6s;
          z-index: 3;
        }

        .cam,
        .arrow {
          opacity: 0;
          transition: opacity 0.6s;
          z-index: 3;
          display: block;
        }

        .arrow {
          content: "";
          width: 16px;
          height: 16px;
          top: 8px;
          background-repeat: no-repeat;
          background-image: url("./images/arrow-search.svg");
          position: absolute;
          z-index: 3;
          left: -9999px;

          &:hover {
            cursor: pointer;
          }
        }

        input {
          left: 110%;
          width: 110%;
          transition: left 0.3s ease, right 0.3s ease;
          position: absolute;
          height: 48px;
          top: -7px;
          border-radius: 5px;
          padding-left: 48px;
        }

        & {
          display: flex !important;
          position: fixed;
          order: -1;
          left: 0;
          right: 0;
          z-index: 1;
          top: 0;
        }

        &.mobile-open {
          input {
            left: -16px;
            background-color: white;

            &::placeholder {
              color: #aaa;
            }
          }

          .arrow {
            left: 0;
            opacity: 1;
            left: 5px;
            top: 10px;
          }

          .zoom {
            content: "";
            background-image: url("./images/zoom.svg");
            position: absolute;
            width: 24px;
            height: 24px;
            right: 35px;
            top: 6px;
            opacity: 1;
          }

          .cam {
            content: "";
            background-image: url("./images/camera.svg");
            position: absolute;
            width: 25px;
            height: 25px;
            right: 0px;
            top: 6px;
            opacity: 1;
          }

          .rtl-class & .zoom {
            left: 0;
            right: unset;
          }

          .rtl-class & .cam {
            left: 30px;
            right: unset;
          }

          .rtl-class & {
            input {
              left: unset;
              right: -16px;
              padding-left: 16px;
              padding-right: 48px;
            }

            .arrow {
              transform: rotate(180deg);
              left: unset;
              right: 0;
            }
          }
        }

        .rtl-class & input {
          left: unset;
          right: calc(100vw - 16px);
        }
      }

      .zoom {
        .rtl-class & {
          left: 16px;
          right: unset;
        }

        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }

        &.show {
          background-image: url("./images/zoom.svg");
          z-index: 2;
        }
      }

      .cam {
        .rtl-class & {
          left: 45px;
          right: unset;
        }

        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }

        &.show {
          background-image: url("./images/camera.svg");
          z-index: 2;
        }
      }

      &.show {
        .search-overlay {
          position: fixed;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: rgba(0, 15, 20, 0.6);
          z-index: 1;
        }

        input {
          z-index: 2;
          background: white;
          color: black;

          &::placeholder {
            color: #aaa;
          }
        }
      }
    }

    .button-container {
      align-items: center;

      @include mq($q16) {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      a {
        background: linear-gradient(180deg, #30c720 0%, #1da310 100%);
        box-shadow: 0 4px 13px rgba(0, 0, 0, 0.12);
        border-radius: 30px;
        padding: 0.5rem 1rem;
        border: $secondary;
        text-transform: uppercase;

        &:focus {
          outline: none;
        }

        p {
          color: white !important;
          letter-spacing: 0.6px;
          font-weight: 700;
          font-family: $font-button-family !important;
        }
      }

      @media (max-width: ($grid-breakpoint-lg)-1) {
        display: none !important;
      }
    }

    .system-icon-container {
      margin: $spacer-4 $spacer-12;
      position: relative;

      img {
        padding: $spacer-2;
      }

      @media (max-width: ($grid-breakpoint-lg)-1) {
        display: none !important;
      }

      .notifications-dropdown {
        min-width: 352px;
        top: 50px !important;

        .notifications-header {
          justify-content: space-between;
          text-align: right;
          padding: $spacer-3;
        }
      }
    }

    .lang {
      border-radius: 30px;
      background-color: $primary-75;
      cursor: pointer;

      .langs_block {
        display: none;
        position: absolute;
        background: white;
        box-shadow: 0 0 32px rgba(48, 17, 0, 0.1);
        border-radius: 8px;
        top: 50px;
        right: -64px;
        z-index: 10000;

        .lang_block {
          display: flex;
          align-items: center;

          &:first-child {
            border-radius: 8px 8px 0 0;
          }

          img {
            padding: $spacer-3 !important;
          }

          &:hover {
            background: $primary-5;

            p {
              color: $primary !important;
            }
          }
        }

        .selected_lang {
          background: $primary-5;

          p {
            color: $primary !important;
          }
        }

        &::before {
          content: "";
          top: -11px;
          position: absolute;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 11px solid white;
          left: 43%;
        }
      }

      &:hover {
        .langs_block {
          display: unset;
        }
      }
    }

    .profile,
    .menu_dropdown {
      cursor: pointer;
      position: relative;

      .profile_block,
      .menu_dropdown_block {
        display: none;
        position: absolute;
        background: white;
        box-shadow: 0 0 32px rgba(48, 17, 0, 0.1);
        border-radius: 8px;
        top: 90px;
        right: -10px;
        z-index: 10000;

        .rtl-class & {
          right: unset;
          left: -10px;
        }

        hr {
          margin: 0;
          border-top: 2px solid #f2f4f5;
        }

        a {
          display: flex;
          align-items: center;
          white-space: pre;
          padding: $spacer-2 !important;
          color: black !important;

          &:first-child {
            border-radius: 8px 8px 0 0;
          }

          img {
            padding: $spacer-2 !important;
          }

          p {
            padding: $spacer-2 !important;
          }

          &.active,
          &:hover {
            background: $primary-5 !important;

            img {
              filter: invert(51%) sepia(53%) saturate(3911%) hue-rotate(-7deg) brightness(114%) contrast(5);
            }

            p {
              color: $primary !important;
            }
          }
        }

        &::before {
          content: "";
          top: -11px;
          position: absolute;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 11px solid white;
          right: 16px;

          .rtl-class & {
            right: unset;
            left: 16px;
          }
        }
      }

      &:hover {
        .profile_block,
        .menu_dropdown_block {
          display: unset;
        }
      }
    }

    .icon-user {
      .no-img {
        background-color: white;
        border-radius: 30px;
      }

      .existed-img {
        border-radius: 30px;
        width: 40px;
        height: 40px;
        padding: 0;
      }
    }

    .d-name {
      align-items: center;
      padding: 0 $spacer-2;

      .user-name {
        text-transform: uppercase;
        font-size: 14px;
        color: white !important;
        font-family: $font-button-family !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @include mq($q20) {
          max-width: 230px;
        }

        @include mq($q19) {
          max-width: 150px;
        }
      }

      @media (max-width: ($grid-breakpoint-lg)-1) {
        display: none !important;
      }
    }

    .d-arrow {
      @media (max-width: ($grid-breakpoint-lg)-1) {
        display: none !important;
      }
    }

    .alert-icon {
      width: 16px;
      height: 16px;
      right: -6px;
      top: -5px;
      background: linear-gradient(180deg, #30c720 0%, #1da310 100%);
      border: 3px solid #fe5900;
      border-radius: 30px;
      position: absolute;
      font-weight: 700;
      font-family: $font-button-family !important;
      font-size: 10px;
      line-height: 14px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      text-align: center;
      color: white;
      justify-content: center;
      padding: 7px;

      &.mobile-badge {
        background: #fe5900;
        border: 0;
      }
    }

    .d-mobile {
      @media (min-width: $grid-breakpoint-lg) {
        display: none !important;
      }
    }

    .auth_menu {
      background-color: $primary-5;
      justify-content: initial;

      a {
        color: #fe5900 !important;
      }

      ul {
        display: flex;
        justify-content: initial;
        background-color: $primary-5;

        @include container;

        li {
          padding: 0.5rem 1rem;

          p {
            color: $primary !important;
          }
        }
      }
    }
  }
}

#drawer-toggle {
  position: absolute;
  opacity: 0;
}

#drawer-toggle-label {
  margin: 0;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
}

#drawer {
  text-align: initial;
  position: fixed;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: white;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;

  .rtl-class & {
    border-radius: 10px 0 0;
    left: unset;
    right: -100%;
  }

  .input-search-drawer {
    margin-top: $spacer-3;
    position: relative;

    input {
      border-radius: 5px;
      width: 100%;
      background: white;
      border: 0 white;
      padding: $spacer-3;
      position: relative;
      padding-left: 44px;

      .rtl-class & {
        padding-left: 44px;
        padding-right: 44px;
      }

      &::placeholder {
        color: #aaa;
      }

      &:focus {
        outline: none;
      }

      background: $primary-75;
      color: white;

      &::placeholder {
        color: white;
      }
    }

    &::after {
      content: "";
      background-image: url("./images/zoom-white.svg");
      position: absolute;
      width: 24px;
      height: 24px;
      left: 11px;
      top: 15px;

      .rtl-class & {
        left: 11px;
        right: 11px;
      }
    }

    &::after {
      content: "";
      background-image: url("./images/camera.svg");
      position: absolute;
      width: 25px;
      height: 25px;
      left: 11px;
      top: 15px;

      .rtl-class & {
        left: 11px;
        right: 11px;
      }
    }
  }

  .info_block {
    .icon-user img {
      width: 80px;
      height: 80px;

      &.no-img {
        padding: 16px;
        border-radius: 50%;
      }
    }

    .d-user-info {
      p {
        color: $primary-5 !important;
      }

      margin-left: $spacer-4;

      @media (max-width: ($grid-breakpoint-sm)) {
        margin-left: $spacer-3;
      }

      .rtl-class & {
        margin-left: unset;
        margin-right: $spacer-4;

        @media (max-width: ($grid-breakpoint-sm)) {
          margin-right: $spacer-3;
        }
      }
    }

    .no-img-user {
      border-radius: 50%;
      width: 80px;
      height: 80px;
      padding: 24px;
      background-color: white;

      img {
        width: 32px;
        height: 32px;
      }
    }

    &.not_loggedin {
      p span {
        font-weight: bold;
      }
    }
  }

  .footer-menu {
    margin: auto 1.5rem 1.5rem;
    border-top: 1px solid #f2f4f5;

    a {
      p {
        font-weight: 500;
      }
    }

    p {
      color: $dark-75;
      font-size: 14px;
      line-height: 24px;
      font-family: $font-button-family;
    }

    @media (max-width: 500px) {
      & > div {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        gap: 3px;
      }
    }
  }

  .addView {
    @media (max-width: ($grid-breakpoint-sm)) {
      display: flex !important;
      flex-direction: column !important;
    }

    @media (max-width: ($grid-breakpoint-smm)) {
      display: flex !important;
      flex-direction: column !important;
    }
  }
}

/* checked styles (menu open state) */
#drawer-toggle:checked ~ #drawer-toggle-label {
  height: 100%;
  width: calc(100% - 370px);
  background: transparent;
  top: 0;

  img {
    opacity: 0;
  }

  position: fixed;
}

#drawer-toggle:checked ~ #drawer-toggle-label,
#drawer-toggle:checked ~ header {
  left: 370px;

  .rtl-class & {
    left: unset;
    right: 370px;
  }
}

#drawer-toggle:checked ~ #drawer {
  left: 0;

  .rtl-class & {
    left: unset;
    right: 0;
  }
}

#drawer-toggle-label {
  -webkit-touch-callout: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  display: block;
  background: rgba(255, 255, 255, 0);
  z-index: 1;
}

#drawer-toggle:checked ~ #page-content {
  margin-left: 370px;

  .rtl-class & {
    margin-left: unset;
    margin-right: 370px;
  }
}

/* Menu item styles */
#drawer ul {
  list-style-type: none;
  display: unset !important;

  .lang_select {
    display: block;
    margin-top: 0;
    border: 1px solid #ffd5bf;
    border-top: none;

    .lang_wrap {
      border: none;
    }
  }

  .lang_li {
    margin-bottom: 0;
  }

  li {
    margin: $spacer-3;

    .lang_wrap {
      display: flex !important;
      justify-content: space-between;
      border: 1px solid #ffd5bf;
      border-radius: 5px;
    }

    a {
      padding: $spacer-3 $spacer-3;
    }

    img {
      width: 23px;
      height: 23px;
    }
  }
}

#drawer ul a {
  p {
    color: $dark-75 !important;
    margin-left: $spacer-3;
    margin-right: $spacer-3;
    font-weight: 500;

    .rtl-class & {
      margin-left: unset;
      margin-right: $spacer-3;
    }
  }

  display: block;
  color: #c7c7c7;
  padding: $spacer-4 0;
  text-decoration: none;

  &:hover {
    background-color: unset;
    cursor: pointer;
    color: unset;
  }

  &.active_mobile {
    color: $primary !important;
    background-color: $primary-5;
    border-radius: 5px;

    img {
      filter: invert(51%) sepia(53%) saturate(3911%) hue-rotate(-7deg) brightness(114%) contrast(5);
    }

    p {
      color: $primary !important;
    }
  }
}

#drawer ul a:hover {
  color: white;
}

/* Responsive MQ */

@media all and (max-width: 350px) {
  #drawer-toggle:checked ~ #drawer-toggle-label {
    height: 100%;
    width: 50px;
  }

  #drawer-toggle:checked ~ #drawer-toggle-label,
  #drawer-toggle:checked ~ header {
    left: calc(100% - 50px);

    .rtl-class & {
      left: unset;
      right: calc(100% - 50px);
    }
  }

  #drawer-toggle:checked ~ #drawer {
    width: calc(100% - 50px);
    padding: 20px;

    @media (max-width: ($grid-breakpoint-sm)) {
      width: calc(100% - 36px);
    }
  }

  #drawer-toggle:checked ~ #page-content {
    margin-left: calc(100% - 50px);

    .rtl-class & {
      margin-left: unset;
      margin-right: calc(100% - 50px);
    }
  }
}

.drawer-header {
  min-height: 128px;

  #drawer-toggle-label {
    position: unset !important;
  }
}

.fs {
  @media (min-width: ($grid-breakpoint-xs)) and (max-width: ($grid-breakpoint-sm)) {
    font-size: 14px !important;
    line-height: 1.5rem !important;
  }

  @media (min-width: ($grid-breakpoint-sm)) and (max-width: ($grid-breakpoint-md)) {
    font-size: 15px !important;
  }
}

.image_picker {
  .settings_wrap {
    overflow: hidden;
    position: relative;
    padding: 15px;

    @include mq($q14) {
      padding: 0;
    }

    .rtl-class & {
      @include mq($q14) {
        padding: 0;
      }
    }

    .drop_target {
      cursor: pointer;
      width: 100%;
      padding: 0;
      margin: auto;

      label {
        display: none;
      }

      input[type="file"] {
        visibility: hidden;
        display: none;
      }

      .image_preview {
        div {
          div {
            position: relative;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border: solid 0 rgba(172, 172, 172, 0.71) !important;
            transition: all 0.2s;
            overflow: hidden;
            max-width: 100%;
            max-height: 100%;
            z-index: 1;
            display: block;

            label {
              width: 100%;
            }
          }
        }

        .noneImage {
          background-image: none !important;
          background-color: transparent !important;
        }

        display: inline-flex;

        .existed-image {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          width: 92px;
          height: 92px;
          background-color: white;
          left: 0;
          right: 0;

          // @include mq($q12) {
          //   width: 80px;
          //   height: 80px;
          // }

          //img {
          //  display: flex;
          //  vertical-align: middle;
          //  align-items: center;
          //  margin: auto;
          //  max-width: 100%;
          //  max-height: 100%;
          //
          //}
        }

        .no-image {
          display: flex;
          align-items: center;
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: white;
          text-align: end;

          img {
            display: flex;
            vertical-align: middle;
            align-items: center;
            margin: auto;
            max-width: 100%;
            max-height: 100%;
            width: 50px;
            height: 50px;
          }
        }

        position: relative;
      }

      &:hover {
        a:not([href]):not([tabindex]):hover {
          //color: $button-color;
        }

        .upload {
          opacity: 0.7;
        }
      }

      &.dropping {
        border-color: #264e86;
      }
    }
  }
}

.mobile-fixed-menu {
  display: none;

  > div {
    margin: 0 4px;

    p {
      font-size: 12px !important;
    }
  }

  @media (max-width: ($grid-breakpoint-lg)-1) {
    display: flex;
    background-color: white;
    padding: 12px 8px;
    bottom: 0;
    justify-content: space-around;
    width: 100%;
    z-index: 10000;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.09);
  }

  .alert-icon {
    width: 16px;
    height: 16px;
    right: -9px;
    top: -6px;
    background: #fe5900;
    border: 2px solid white;
    border-radius: 30px;
    position: absolute;
    font-weight: 700;
    font-family: $font-button-family !important;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    color: white;
    justify-content: center;
    padding: 7px;
  }

  .pos-terminal-parent {
    height: 52px;
    display: flex;
    align-items: flex-end;
    position: relative;
    justify-content: center;

    .pos-terminal {
      position: absolute;
      background-color: #fe5900;
      text-align: center;
      width: 50px;
      min-height: 50px;
      top: -25px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 5px solid white;
      border-radius: 50%;

      @media (max-width: 340px) {
        top: -40px;
      }
    }
  }
}

.rtl-class {
  .close-cam {
    left: 20 !important;
  }
}
.ltr-class{
  .close-cam {
    right: 20 !important;
  }
}
.close-cam {
  position: absolute;
  top: 20;
  color: #fff;
  background-color: #e24f00;
  text-align: center;
  width: 40;
  height: 40;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flip-cam {
  background-image: url("./images/swipeCamera.svg");
  background-size: cover; /* Ensure the SVG fits the button */
  background-position: center; /* Center the SVG image */
  position: absolute;
  top: 20px; /* Adjust the position as needed */
  width: 40px;
  height: 40px;
  z-index: 3000;

}

.rtl-class .flip-cam {
  left: 70px !important;
}

.ltr-class .flip-cam {
  right: 70px !important;
}
